<template>
    <div class="dropdown px-1">
        <a :id="'dropdown-menu-link-'+ index" class="btn dropdown-toggle" v-bind:class="{'is-invalid': !isValid, 'no-selection': !selectedOptions}" @click="dropdown()" role="button" data-toggle="dropdown" aria-expanded="false"><span class="title-input">{{headingTitle}}</span></a>
        <div v-if="canClear" @click="clearClicked" class="clear-area"></div>
        <div class="dropdown-menu" :ref="'dropdown-' + index" :aria-labelledby="'dropdown-menu-link'+ index">
            <a v-for="option in options" :key="option.key" class="dropdown-item" v-bind:class="{'selected': isOptionSelected(option)}" @click="optionClick(option)">{{option.label}}</a>
        </div>     
    </div>
</template>
<script>

export default {
    data(){
        return{
            selectedOptions: [],
            headingTitle: this.title
        }
    },
    props:{
        value:
        {
            type: Array,
            default: () => []
        },
        index: Number,
        options:
        {
            type: Array,
            default: () => []
        },
        title:
        {
            type: String,
            default: ""
        },
        canClear:
        {
            type: Boolean,
            default: false
        },
        isValid:
        {
            type: Boolean,
            default: true
        }
    },
    mounted: function()
    {
        document.addEventListener('click', this.handleClickOutside);

        if(this.value.length)
        {
            const value = this.value;
            this.selectedOptions = this.options.filter(obj => {
                return value.includes(obj.key) 
            });
        }
    },
    methods:{
        isOptionSelected(option)
        {
            return this.selectedOptions.length ? this.selectedOptions.includes(option) : false;
        },
        clearClicked()
        {
            this.selectedOptions = null;
        },
        dropdown() {
            // Note that for date clicks are handled by the input
            this.$refs['dropdown-'+this.index].classList.toggle('d-block');
        },
        optionClick(option){
            if(this.selectedOptions.includes(option))
            {
                this.selectedOptions = this.selectedOptions.filter(function(selectedOption){ return selectedOption.key != option.key; })
            }
            else
            {
                this.selectedOptions.push(option);
            }
        },
        // Due to limitations on Vue, headingTitle can not be a computed property because changes on 
        // array are not reactive.
        updateHeadingTitle()
        {
            var result = this.title;

            if(this.selectedOptions.length)
            {    
                // Get selected
                let selectedOptions = this.selectedOptions;
                var selectedFullOptions = this.options.filter(function(option) {
                return selectedOptions.includes(option);
                });

                // Map names
                var names = selectedFullOptions.map(option => option.label);

                // Build string
                result = names.join(", ");
            }

            this.headingTitle = result;
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                if(this.$refs['dropdown-'+this.index] !== undefined)
                {
                    this.$refs['dropdown-'+this.index].classList.remove("d-block");
                }
            }
        }
    },
    watch:
    {
        selectedOptions:
        {
            handler: function(val)
            {
                this.updateHeadingTitle();
                this.$emit('input', val.map(x=> x.key));
            },
            deep: true // Watch for changes on the properties
        }
    }  
}
</script>

<style scoped>
.no-selection
{
    color: #AAAAAA;
}
.title-input{
    white-space: pre-wrap;
}

.clear-area
{
    position: absolute;
    right: 0;
    width: 40px;
    height: 100%;
    top: 0;
    cursor: pointer;
}

.dropdown-clear::after
{
    content: "x";
    display: contents;
    font-weight: bold;
}

.dropdown-toggle
{
    min-width: 125px;
    min-height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
}

.dropdown-toggle.is-invalid
{
    border-color: #e55353;
}

.dropdown-toggle.is-invalid::after
{
    color: #e55353;
}

.in-front
{
    z-index: 999;
}

/deep/a.dropdown-item
{
    cursor: pointer;
}

/deep/a.dropdown-item.selected
{
  text-decoration: none;
  color: #fff!important;
  background-color: rgb(78, 84, 200)!important;
}

.disabled .dropdown-menu
{
    pointer-events: none;
}
</style>