<template>
    <section>
        <div class="container-fluid pt-0 pt-lg-5">
            <div class="row mb-5 justify-content-between">
                <div class="col-12 col-lg-6 my-2">
                    <StatisticsDropdown :class="{'disabled': loading}" v-model="measures" :options="statisticsOptions" title="Escolha Opção" :index="1" class="form-control"></StatisticsDropdown>
                </div>
                <div class="col-12 col-lg-6 d-flex flex-wrap gap-3 justify-content-end my-2 align-items-center">
                    <span class="date-col">De</span>
                    <datepicker 
                        id="start-date"
                        v-model="startDate" 
                        :minimumView="'month'" 
                        :maximumView="'year'" 
                        :format="customFormatter" 
                        :disabledDates="disabledDates" 
                        :language="ptBR"
                        :calendar-button="true"
                        :calendar-button-icon="'bi bi-calendar3'"
                        >   
                    </datepicker>
                    <span class="date-col">Até</span>
                    <datepicker 
                        id="end-date"
                        v-model="endDate" 
                        :minimumView="'month'" 
                        :maximumView="'year'" 
                        :format="customFormatter" 
                        :disabledDates="disabledDates" 
                        :language="ptBR"
                        :calendar-button="true"
                        :calendar-button-icon="'bi bi-calendar3'"
                        >   
                    </datepicker>
                </div>
            </div>
            <CChartLine style="height:70vh" :datasets="datasets" :labels="labels" :options="{ maintainAspectRatio: false }"/>
        </div>
    </section>
</template>

<script>
import StatisticsDropdown from '@/controls/StatisticsDropdown.vue'
import InputGetStatistics from "@/classes/InputGetStatistics";
import { mapState, mapActions } from 'vuex'
import { UTILS_HELPER } from "@/helpers/utils.js";
import Datepicker from 'vuejs-datepicker';
var moment = require('moment');
import {ptBR} from 'vuejs-datepicker/dist/locale'

const MONTHS_NAMES = [ "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro" ];

export default {
    components: { StatisticsDropdown, Datepicker },
    data (){
        return{
            startDate: new Date(new Date().getFullYear() - 1, new Date().getMonth(), 1).toISOString().substr(0, 7),
            endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().substr(0, 7),
            statisticsOptions:
            [
                {key:'Properties', label:'Número de Imóveis Totais',backgroundColor: 'rgb(143, 148, 251)'},
                {key:'Income', label:'Rendas',backgroundColor: 'green'},
                {key:'Accountability', label:'Prestações',backgroundColor: 'orange'},
                {key:'Costs', label:'Custos',backgroundColor: 'red'},
                {key:'Liquidated', label:'Valor Liquidado',backgroundColor: 'blue'},
                {key:'NotLiquidated', label:'Valor por Liquidar',backgroundColor: 'black'},
                {key:'EquityValue', label:'Valor Patrimonial',backgroundColor: 'yellow'},
            ],
            measures: ['Properties'],
            ptBR: ptBR,
            disabledDates: {
                from: new Date()
            }
        }
    },
    methods:{
        ...mapActions('statistics', ['getGraph']),
        updateItemsRequested()
        {
            // Validate inputs
            if(new Date(this.startDate) > new Date(this.endDate))
            {
                return;
            }
            
            if(!this.measures.length)
            {
                return;
            }

            // Make request
            const measuresValue = this.measures;
            const startDateValue = new Date(this.startDate).toISOString().substr(0, 10);
            const endDateValue = new Date(new Date(this.endDate).getFullYear(), new Date(this.endDate).getMonth() + 2, 0, 23, 59).toISOString().substr(0, 10);

            var input = new InputGetStatistics(startDateValue, endDateValue, measuresValue);
            this.getGraph(input); 
        },
        customFormatter(date) {
            moment.locale('pt');
            return moment(date).format(`MMMM-YYYY`);
        }
    },
    computed:{
        loading: function()
        {
            return this.graphOutput == null;
        },
        ...mapState('statistics', ['graphOutput']),
        graphData: function()
        {
            return this.graphOutput && this.graphOutput.data ? this.graphOutput.data : null;
        },
        datasets: function()
        {
            var result = [];

            // Iterate through the selected measures and get corresponding data
            if(this.graphData)
            {
                this.measures.forEach(measure =>{
                    const measuresLabel = this.statisticsOptions.find(x=> x.key == measure).label;
                    const backgroundColor = this.statisticsOptions.find(x=> x.key == measure).backgroundColor;
                    
                    // Get the object and get the property values
                    var data = this.graphData[UTILS_HELPER.convertToCamelCase(measure)];
                    data = Object.keys(data).map(function(k){return data[k]});

                    result.push({
                        data: data,
                        label: measuresLabel,
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: 'rgb(143, 148, 251)',
                        borderColor: backgroundColor,
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: 'rgb(78, 84, 200)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: backgroundColor,
                        pointHoverBorderColor: 'rgb(78, 84, 200)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                    });
                });
            }

            return result;
        },
        labels: function()
        {
            var arr = [];
            var datFrom = new Date(this.startDate)
            var datTo = new Date(this.endDate);
            var fromYear =  datFrom.getFullYear();
            var toYear =  datTo.getFullYear();
            var diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();

            for (var i = datFrom.getMonth(); i <= diffYear; i++) {
                arr.push(MONTHS_NAMES[i%12] + " " + Math.floor(fromYear+(i/12)));
            }        

            return arr;
        }
    },
    watch: 
    {
        measures: function()
        {   
            this.updateItemsRequested(); 
        },
        startDate: function(val, oldVal) 
        {
            if(new Date(this.startDate) > new Date(this.endDate))
            {
                alert("Erro! A data de Início tem de ser anterior à data de Fim!");
                this.startDate = oldVal;
            }
            else
            {
                this.updateItemsRequested(); 
            }
        },
        endDate: function(val, oldVal) 
        {
            if(new Date(this.startDate) > new Date(this.endDate))
            {
                alert("Erro! A data de Início tem de ser anterior à data de Fim!");
                this.endDate = oldVal;
            }
            else
            {
                this.updateItemsRequested(); 
            }
        },        
    }
}
</script>

<style scoped>
.date-col{
    font-weight: bold;
}

</style>
